import Api from "../core/api";
import authHeader from "./auth-token";

import {
  localStorageOrderKey,
  localStorageUserKey
} from "./keys-localstorage";

const setPropety = (value, property) => {
  let order = localStorage.getItem(localStorageOrderKey)

  order = JSON.parse(order)
  if (!order || typeof order !== 'object') {
    order = {}
  }

  order[property] = value

  localStorage.setItem(localStorageOrderKey, JSON.stringify(order));
};


const getPropety = (property) => {
  let order = localStorage.getItem(localStorageOrderKey)
  order = JSON.parse(order)
  let value = null

  if (order && typeof order === 'object' && order[property]) {
    value = order[property]
  }

  return value
};

const getDataUser = (property) => {
  let data = localStorage.getItem(localStorageUserKey)
  data = JSON.parse(data)
  let value = null
  if (data && typeof data === 'object' && data[property]) {
    value = data[property]
  }

  return value
};

const getOrder = () => {
  let order = localStorage.getItem(localStorageOrderKey)
  order = JSON.parse(order)
  console.log(order);

  if (order && typeof order === 'object') {
    return order
  }

  return {}
};

const getHistory = () => {
  return Api.exec("v1/orders", { token: authHeader() }, "GET").then((response) => {

    if (response) {
      return response;
    }

    return []
  });
};

const getCountRecipes = (data) => {
  return Api.exec("/v1/orders/get-count-recipes", { token: authHeader(), data }, "GET").then((response) => {
    if (response && response.status && (response.status === 200)) {
      return Promise.resolve(response);
    }

    return Promise.reject(response);
  });
};

const getPDF = (data) => {
  return Api.exec("v1/orders/report", { token: authHeader(), data }, "GET").then((response) => {
    if (response) {
      return response;
    }

    return []
  });
};

const getItemsWebCheckout = (data) => {
  return Api.exec("/v1/orders/check-out", { token: authHeader(), data }, "GET").then((response) => {
    if (response) {
      return response;
    }

    return []
  });
};

const addOrder = (data) => {
  return Api.exec("v1/orders/add", { token: authHeader(), data }, "POST").then((response) => {

    if (response && response.status && response.status === 200) {
      return Promise.resolve(response);
    }

    return Promise.reject(response);
  });
};

const addRecipesToOrder = (data) => {
  return Api.exec("v1/orders/add-items", { token: authHeader(), data }, "POST").then((response) => {

    if (response && response.status && (response.status === 201 || response.status === 200)) {
      return Promise.resolve(response);
    }

    return Promise.reject(response);
  });
};

const getLastBuyerInfo = () => {
  return Api.exec("v1/orders/last-buyer-info", { token: authHeader() }, "GET").then((response) => {
    if (response) {
      return response;
    }

    return {}
  });
};

const getData = (data) => {
  return Api.exec("v1/orders/view", { data }, "GET").then((response) => {
    if (response) {
      return response;
    }

    return null
  });
};

const removeOrder = () => {
  localStorage.removeItem(localStorageOrderKey);
};

export default {
  setPropety,
  getPropety,
  getHistory,
  getPDF,
  getItemsWebCheckout,
  addOrder,
  getOrder,
  getLastBuyerInfo,
  getDataUser,
  removeOrder,
  getData,
  addRecipesToOrder,
  getCountRecipes
};
