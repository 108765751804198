import _ from 'lodash'
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from "react-router-dom"
import { MDBIcon } from "mdbreact";
import ImagePicker from "../../components/imagepicker/react-image-picker"

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import {Animated} from "react-animated-css";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {compose} from "redux";
import { withRouter } from 'react-router';
import { checkBeforeStep, goBackStep } from "../../core/stepvalidations"

import { connect } from "react-redux";
import { setPropety, getPropety } from "../../actions/order";

import { required } from "../../core/validations";

import Skeleton from 'react-loading-skeleton';


import { addRecipesToOrder } from "../../actions/order";
import { getCountRecipes } from "../../actions/order";

import 'font-awesome/css/font-awesome.min.css';
import '../../components/choose-menu/chooseMenu.scss';
import { Helmet } from 'react-helmet';

import {config } from '../../config'
const WEB_ROOT = config.WEB_ROOT;
const HOST = config.HOST;

class ChooseMenu extends React.Component{

  constructor(props) {
    super(props);

    toast.configure()

    let id_plan = this.props.getPropetyInView("id_plan");
    let items = this.props.getPropetyInView("items")

    let is_month_addition = false
    if (this.props.match.params.month) {
        is_month_addition = true
    }


    this.state = {
      isLoading: true,
      menus: [],
      firstMenus: [],
      error: null,
      images: [],
      dishes: !is_month_addition && items && items.length ? items : [],
      id: 0,
      current_week: 1,
      max_items_by_week: 6,
      id_plan: id_plan ? id_plan : 0,
      name_order: this.props.getPropetyInView("name_order"),
      is_month_addition: is_month_addition,
      selectedImages: [],
      canFillRecipes: true
    };

    this.handleEvent = this.handleEvent.bind(this);
    this.onPickImages = this.onPickImages.bind(this);

    this.payReference = React.createRef()
    this.topReference = React.createRef()

  }

  handleEvent(eventKey, url){
    if (eventKey === "1") {
      url = '?is_with_meat_chicken=1';
    }else if (eventKey === "2") {
      url = '?is_with_meat_pork=1';
    }else if (eventKey === "3") {
      url = '?is_with_beef=1';
    }else if (eventKey === "4") {
      url = '?is_with_meat_fish=1&is_with_meat_shellfish=1';
    }else if (eventKey === "5") {
      url = '?is_without_dairy=1';
    }else if (eventKey === "6") {
      url = '?is_without_gluten=1';
    }else if (eventKey === "7") {
      url = '?is_without_oven=1';
    }else if (eventKey === "8") {
      url = '?air_fryer=1';
    }else {
      url = '?';
    }
    this.fetchMenus(url);
  }

  componentDidMount() {
    let diet;
    let filter =  ""

    if (this.state.is_month_addition && this.props.match.params.diet) {
      diet = parseInt(this.props.match.params.diet);
      filter = "?diet="+diet
    } else {
      diet = this.props.getPropetyInView("id_diet");
    }

    if (this.state.is_month_addition) {
      this.getCountRecipes();
    }

    if (diet === 1) {
      this.fetchMenus(filter);
    } else {
      this.fetchMenusVegetarian();
    }

    let currentStep = 5
    if (!this.state.is_month_addition && !checkBeforeStep(currentStep)) {
      goBackStep(currentStep)
    }

    // change current week if dishes already
    if (this.state.dishes.length === 24) {
      this.changeStateWeek(4)
    }
  }

  getCountRecipes() {
    let month = this.props.match.params.month
    let id_order = this.props.match.params.id_order
    this.props.getCountRecipesInView(id_order, month)
    .then((data) => {
      this.setState({successful: true, error: null});
      if (data.items && data.items > 0) {
        this.setState({canFillRecipes: false});
      }
    })
    .catch(() => {
      this.setState({successful: false, error: {message: "Un error inesperado ha ocurrido. No se ha podido consultar la cantidad de recetas para este mes."}});
    });
  }

  fetchMenus(filter) {
    const url_host = HOST + 'v1/recipes';
    let service = '';
    if (filter === undefined) {
      service = url_host;
    } else{
      service = url_host+filter;
    }

    fetch(service)
    .then(response => response.json())
    .then(data => {
      this.setState({
        isLoading: false,
        menus: data,
      })

      if (!this.state.firstMenus.length) {
        this.setState({ firstMenus: data})
      }
    }

    )
    .catch(error => this.setState({ error, isLoading: false }));
  }

  fetchMenusVegetarian() {
    fetch(HOST + 'v1/recipes?diet=2')
    .then(response => response.json())
    .then(data => {
      this.setState({
        menus: data,
        isLoading: false,
      })

      if (!this.state.firstMenus.length) {
        this.setState({ firstMenus: data})
      }
    }
    )
    .catch(error => this.setState({ error, isLoading: false }));
  }

  onPickImages(image) {
    const { current_week, dishes } = this.state;
    let newRecipe = {id_recipe: image.id_recipe, week: current_week }
    let currentRecipes = dishes

    let totalWeek = this.getTotalRecipesByWeek(current_week)

    // no duplicate items and check max items by week
    if (!_.some(dishes, newRecipe)) {

      if (totalWeek < this.state.max_items_by_week) {
        currentRecipes.push(newRecipe)
      } else {
        toast.info('¡Solo puedes escoger '+ this.state.max_items_by_week + ' platos por semana!', { toastId: "only-six", progressClassName: 'fancy-progress-bar', position: toast.POSITION.BOTTOM_RIGHT})
      }

      if (totalWeek < this.state.max_items_by_week && this.recipeInAnotherWeek(newRecipe.id_recipe, current_week) > 0) {
        let warnOptions = { toastId: "another-week",
                            autoClose: 12000,
                            progressClassName: 'fancy-progress-bar',
                            position: toast.POSITION.BOTTOM_RIGHT
        }
        toast.warning('Este plato ya lo seleccionaste para otra semana. Si también lo deseas para esta, déjalo seleccionado.', warnOptions)
      }

    } else { // remove recipe
      _.remove(currentRecipes, newRecipe)
    }

    this.setState({ dishes: currentRecipes})

    if (!this.state.is_month_addition && currentRecipes.length === 24) {
      this.props.setPropetyInView(currentRecipes, "items")
      toast.success('¡Has elegido los 24 platos de tu mes!, Desliza hacia arriba para continuar', { toastId: "total-recipes",
                                                                                                    autoClose: false, progressClassName: 'fancy-progress-bar',
                                                                                                    position: toast.POSITION.BOTTOM_RIGHT,
                                                                                                    onClick: () => {
                                                                                                                      this.payReference.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
                                                                                                    }
                                                                                                  })
    }
  }

  changeStateWeek(week = null) {
    const { current_week } = this.state;
    if (current_week <= 4) {
      this.setState(prevState => ({
        current_week: week ? week : (prevState.current_week + 1)
      }))
    }
  }

  recipeInAnotherWeek(id_recipe, current_week) {
    const { dishes } = this.state;
    let count = 0
    for (var i = 0; i < dishes.length; i++) {
      let dishe = dishes[i]

      if (dishe.id_recipe === id_recipe && dishe.week !== current_week ) {
        count++
      }

    }
    return count
  }

  getTotalRecipesByWeek(w) {
    const { dishes } = this.state;
    let total = 0
    for (var i = 0; i < dishes.length; i++) {
      let dishe = dishes[i]

      if (dishe.week === w) {
        total++
      }

    }
    return total
  }

  nextWeek() {
    const { current_week, dishes } = this.state;
    if ((current_week === 1 && dishes.length === 6) ||
        (current_week === 2 && dishes.length === 12) ||
        (current_week === 3 && dishes.length === 18)) {

        this.changeStateWeek()
        this.setState({menus: this.state.firstMenus})
    }
  }

  changeWeek(week) {
    const { dishes } = this.state;
    if ((week === 1 && dishes.length >= 0) ||
        (week === 2 && dishes.length >= 6) ||
        (week === 3 && dishes.length >= 12) ||
        (week === 4 && dishes.length >= 18)) {

          this.changeStateWeek(week)
          this.setState({menus: this.state.firstMenus})
    }
  }

  renderNextText() {
    const { current_week } = this.state;
    const text = "Continúa con la semana " + (current_week + 1)
    toast.info(text, { toastId: "next-week-"+current_week,
                        autoClose: false,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        onClick: (e) => {
                          this.nextWeek();
                        }
              })

    return (
      <span onClick={(e) => this.nextWeek()} className="color-link"> {text} </span>
    )
  }

  renderEndText() {
    const { is_month_addition } = this.state;
    return (
      <div>
      <p className="mt-4 mb-0 text-cab-menu">¡Has elegido los 24 platos de tu mes!.</p>
      { !is_month_addition ?
         (<p className="text-cab-menu">Si elegiste un plan de más de 1 mes, podrás armar tus próximos menús desde tu perfil, una vez realizado el pago de tu plan. </p>)
         : null
      }
      </div>
    )
  }

  renderTitleText() {
    const { current_week, dishes } = this.state;
    return (
      <p className="mt-4 text-cab-menu" ref={this.topReference}>
        Elige los 6 platos de tu Semana {current_week}

        { ((current_week === 1 && dishes.length === 6) ||
           (current_week === 2 && dishes.length === 12) ||
           (current_week === 3 && dishes.length === 18))
           ? this.renderNextText() :  null
        }

      </p>
    )
  }

  getClassWeek(w, min_fill) {
    const { dishes } = this.state;
    return dishes.length >= min_fill ? 'fill-week-'+w : 'week-disable'
  }

  onChangeNameOrder = (e) => {
    this.setState({name_order: e.target.value});
  };

  handleForm = (e) => {
    e.preventDefault();

    this.setState({successful: false});

    this.form.validateAll();

    const { is_month_addition } = this.state;

    if (this.checkBtn.context._errors.length === 0 && this.state.dishes.length === 24) {

      if (is_month_addition) {
        // save plates automatically
        this.saveRecipesMonth()
      } else {
        this.props.setPropetyInView(this.state.name_order, "name_order")
        this.props.history.push('/choose-plan/' + this.state.id_plan);
        window.location.reload();
      }
    }
  };

  saveRecipesMonth() {
    let month = this.props.match.params.month
    let id_order = this.props.match.params.id_order

    this.props.addRecipesToOrderInView(id_order, month, this.state.dishes)
    .then((data) => {
      this.setState({successful: true});
      toast.success('¡Has agregado los 24 platos del siguiente mes!. ¿Volver al historial?' , { toastId: "add-month-recipes",
                                                                          autoClose: false,
                                                                          progressClassName: 'fancy-progress-bar',
                                                                          position: toast.POSITION.BOTTOM_RIGHT,
                                                                          onClick: () => {
                                                                                            this.props.history.push("/");
                                                                                            window.location.reload();
                                                                          }
                                                                      })
      this.props.removeOrderInView()
    })
    .catch(() => {
      this.setState({successful: false});
    });
  }

  render(){
    const { isLoading, canFillRecipes, menus, error, current_week, dishes, is_month_addition} = this.state;
    let activeWeekClass = 'active-week';

    const loadingGrid = [1,2,3,4,5,6];

    return (

      <div>
      <Helmet>
          <title>arma tu menú</title>
          <meta name="description" content="Llegó el momento más delicioso de todos. En este espacio el usuario puede elegir los platos que comerá cada mes. Puede elegir 6 por semana. Encuentra una gran variedad de opciones de platos que puede filtrar por diferentes criterios: Tipo de proteína: pollo, cerdo, res, pescados y/o mariscos. Sin gluten. Sin lácteos. Sin horno. Con Air Fryer. Elige los platos semana a semana hasta completar los 24 del mes.Puede personalizar su recetario poniéndole un nombre. Nuevos platos cada mes" />
      </Helmet>

      <Row>
      <Col className="background-left-menu" md={4}>
      <div className="offset-md-3">
      <Row className="mt-5">
      <a href={WEB_ROOT}>
      <Col md={5}><img src="../homegreen.png"  className="d-inline-block align-top" alt=""/></Col>
      </a>
      </Row>
      </div>

      <div className="offset-md-4 offset-1">
      <Row className="mt-5">
      <Col md={12}>
      <Link to="/plan">
      <p className="mt-1 text-back-menu"><img src="../back-black.png" alt="volver"/> Volver</p>
      </Link>
      <h2 className="mt-4 text-init-menu">!LLEGÓ EL MOMENTO MÁS DELICIOSO DE TODOS!</h2>
      <h2 className="text-subinit-menu">Elige los platos que comerás cada semana del mes (6 por semana).</h2>
      </Col>
      </Row>
      </div>

      <div className="offset-md-4 offset-1">
      <Row className="mt-5">
      <Col md={11}>
      <div className="week-menu">
        <p onClick={(e) =>this.changeWeek(1)} className={ this.getClassWeek(1, 6) }>
          <span className={ current_week === 1 ? activeWeekClass : this.getClassWeek(1, 6) }>
            Semana 1
          </span>
           <MDBIcon className="float-right size-icon-menu" icon="check-circle"/>
        </p>
      </div>
      <div className="week-menu">
        <p onClick={(e)=>this.changeWeek(2)} className={ this.getClassWeek(2, 12)}>
          <span className={ current_week === 2 ? activeWeekClass : this.getClassWeek(2, 12) }>
            Semana 2
          </span>
           <MDBIcon className="float-right size-icon-menu" icon="check-circle"/>
        </p>
      </div>

      <div className="week-menu">
        <p onClick={(e)=>this.changeWeek(3)} className={ this.getClassWeek(3, 18) }>
          <span className={ current_week === 3 ? activeWeekClass : this.getClassWeek(3, 18) }>
            Semana 3
           </span>
           <MDBIcon className="float-right size-icon-menu" icon="check-circle"/>
        </p>
      </div>

      <div className="week-menu">
        <p onClick={(e)=>this.changeWeek(4)} className={ this.getClassWeek(4, 24) }>
          <span className={ current_week === 4 ? activeWeekClass : this.getClassWeek(4, 24) }>
            Semana 4
          </span>
           <MDBIcon className="float-right size-icon-menu" icon="check-circle"/>
        </p>
      </div>

      </Col>
      </Row>
      </div>

      <Form ref={c => { this.form = c }} onSubmit={this.handleForm.bind(this)}>
      <Row className="mt-5 menu-name">
          <div className="offset-md-4 offset-1" ref={this.payReference}>

            { !is_month_addition && current_week === 4 && dishes.length === 24 ?
              <Col md={12}>
                <label className="mt-1 name-almorza-menu">Ponle un nombre a tu Almorzadiario</label>

                <Input
                type="text"
                className="menu name-input-menu"
                name="name_order"
                placeholder="Ej: Recetario para mes vegetariano"
                value={this.state.name_order}
                onChange={this.onChangeNameOrder}
                validations={[required]}
                />

              </Col>
            : null }

          </div>
        </Row>

        <Row className="mt-4">
          <div className="offset-md-8 offset-1 mb-3">
            { current_week === 4 && dishes.length === 24 ?
              <Col md={8}>
                <Animated animationIn="bounceIn" animationOut="zoomOutUp" animationInDuration={4000} animationInDelay={400} isVisible={current_week === 4 && dishes.length === 24}>
                    <button type="submit" className="btn btn-green-steps-menu float-right">
                      { (is_month_addition) ? "GUARDAR" : "IR A PAGAR"}
                      <img className="image ml-1" src="../after.png" alt="next"/>
                      <img className="image ml-1" src="../after-green.png" alt=""/>
                    </button>
                </Animated>
              </Col>
            : null }
          </div>
        </Row>

      <CheckButton style={{ display: "none" }} ref={c => { this.checkBtn = c }} />
      </Form>


      </Col>

      <Col className="background-right-menu" md={8}>
      <div className="offset-md-1 col-lg-10 col-12">
      <div className="mt-5 row">

      <Col md={8}>
        {
           current_week === 4 && dishes.length === 24  ?
           this.renderEndText() : this.renderTitleText()
        }

      </Col>

      <div className=" ">
      <Col className="" md={2}>

      <Dropdown alignRight onSelect={this.handleEvent}>
      <Dropdown.Toggle className="dropdown-color" id="dropdown-basic">
      <p className="mt-3 mb-1 text-cab-menu">Filtra por preferencias <img src="../filter.png"  className="ml-2 d-inline-block align-middle " alt="filter"/></p>
      </Dropdown.Toggle >
      <Dropdown.Menu className="text-right dropdown-background" >
      <Dropdown.Item className="focus-item" eventKey="1">Recetas con pollo</Dropdown.Item>
      <Dropdown.Item className="focus-item" eventKey="2">Recetas con cerdo</Dropdown.Item>
      <Dropdown.Item className="focus-item" eventKey="3">Recetas con carne de res</Dropdown.Item>
      <Dropdown.Item className="focus-item" eventKey="4">Recetas con pescado y/o mariscos</Dropdown.Item>
      <Dropdown.Item className="focus-item" eventKey="5">Recetas sin lácteos</Dropdown.Item>
      <Dropdown.Item className="focus-item" eventKey="6">Recetas sin gluten</Dropdown.Item>
      <Dropdown.Item className="focus-item" eventKey="7">Recetas sin horno</Dropdown.Item>
      <Dropdown.Item className="focus-item" eventKey="8">Recetas con Air Fryer</Dropdown.Item>
      <Dropdown.Item className="focus-item" eventKey="9">Ver todos</Dropdown.Item>
      </Dropdown.Menu>
      </Dropdown>
      </Col>
      </div>
      </div>

      </div>



      <div className="offset-md-1 col-lg-10 col-12">
      <div className="mt-5 row">

      {error ? <p className="error-description">{error.message}</p> : null}

      {!canFillRecipes ? <p className="error-description ml-3"> Este mes ya tiene platos agregados. </p> : null}

      {!isLoading && canFillRecipes ? (
        menus.map(menu => {
          const { id, name, description, image_desktop, color} = menu;
          const imageList = [image_desktop]

          return (
            <Col md={4}>
            <div style={{ backgroundColor: color }}>
            <center>
            <ImagePicker
            images={imageList.map((image, i) => ({src: image, value: id, id_recipe: id}))}
            onPick={() => this.onPickImages({id_recipe: id})}
            selectedImages={this.state.dishes}
            max_select={this.state.max_items_by_week}
            total_added= {this.getTotalRecipesByWeek(current_week)}
            current_week= {current_week}
            />
            </center>
            </div>
            <h2 className="name-menu-menu">{name}</h2>
            <p className="description-menu-menu"> {description}</p>
            </Col>


          );
        })
      ) : ( !canFillRecipes ? (null): ((
        // Loading skeleton
        loadingGrid.map(item => { return (
          <Col md={4}>
          <Skeleton height={130} />

          <h2 className="name-menu-menu mt-1"><Skeleton/></h2>
          <p className="description-menu-menu"> <Skeleton count ={3} /></p>
          </Col>
        );
      })
      )))}
      </div>

      </div>

      <div className="offset-md-5 offset-3">
      <Row className="mt-5">
      <Col md={4}>
      <p className="copyright-menu">ALMORZADIARIO © 2021</p>
      </Col>
      </Row>
      </div>

      </Col>

      </Row>

      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getPropetyInView: (value) => {
    return dispatch(getPropety(value))
  },
  setPropetyInView: (value, property) => {
    dispatch(setPropety(value, property))
  },
  addRecipesToOrderInView: (id_order, month, items) => {
    return dispatch(addRecipesToOrder(id_order, month, items))
  },
  getCountRecipesInView: (id_order, month) => {
    return dispatch(getCountRecipes(id_order, month))
  },
});

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChooseMenu);
