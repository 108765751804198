import axios from 'axios';

import { config } from '../config'
const host = config.HOST;

const exec = (serviceURL, {data, token, ...customConfig} = {}, method=null) => {
  let headers =  {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
  }

  const config = {
    method: method ? method : 'GET',
    headers: {
      ...headers
    }
  }

  if (data && config.method === 'GET') {
    config.params = data
  } else if (data) {
    config.data = JSON.stringify(data);
  }

  if (token) {
    config.headers.Authorization = "Bearer "+ token
  }

  config.url = `${host}${serviceURL}`;

  return axios(config).then((resp) => {
    let data = []

    if (resp.data.data) {
      data = resp.data.data
    } else {
      data = resp.data
    }

    if (resp.data.status) {
      data.status = resp.data.status
    } else if (resp.status) {
      data.status = resp.status
    }

    return data

  }).catch((err) => {
    return err
  })
};

export default {
  exec
};
